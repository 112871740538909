@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icon-loading.svg');
			background-size: auto 80%;
			background-position: center right;
		}
		
	}

	&.switch {
		background-color: #CB2D33;
		width: 3.5em;
		height: 1.44em;
		line-height: 1.44;
		border-radius: 0.722em;
		padding: 0 0.5em;
		@include flex('flex-end', 'center');
		input {
			position: absolute;	
			opacity: 0;
			width: 0;
			height: 0;
		}
		span {
			position: absolute;
			left: 0.3em;
			display: block;
			height: 1em;
			width: 1em;
			background-color: white;
			border-radius: 100%;
			@include transition(0.1s, 'all', 'ease-in-out');
		}
		strong {
			color: white;
			text-transform: lowercase;
		}
		&.checked {
			background-color: #0F635B;
			@include flex('flex-start', 'center');
			span {
				background-color: #ffffff;
				@include translate(calc(3.5em - 1.6em));
			}
		}
	}

	&.red-frame {
		width: 100%;
		height: 3em;
		background-image: url('../../../assets/images/buttons/button-red-frame.svg');
		span {
			color: #CB2D33;
			font-size: 1.11em;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	&.red,
	&.red-medium {
		width: 11.25em;
		height: 3em;
		color: white;
		background-image: url('../../../assets/images/buttons/button-red.svg');
		padding-top: 0.25em;
		span {
			display: inline-block;
			text-transform: uppercase;
			font-size: 1.11em;
			font-weight: 600;
			// letter-spacing: 0.2em;	
		}
	}
	&.red {
		background-image: url('../../../assets/images/buttons/button-red.svg');
	}
	&.red-medium {
		width: 15em;
		background-image: url('../../../assets/images/buttons/button-red-medium.svg');
	}

	&.red-wide {
		position: relative;
		width: 22em;
		height: 3.7em;
		color: white;
		background-image: url('../../../assets/images/buttons/button-red-wide.svg');
	}
	

	&.uppercase {
		span {
			text-transform: uppercase;
		}
	}

	&.login {
		width: 100%;
		height: 3em;
		color: white;
		background-image: url('../../../assets/images/buttons/button-login.svg');
		padding-top: 0.25em;
		span {
			display: inline-block;
			text-transform: uppercase;
			font-size: 1.11em;
			font-weight: 700;
			letter-spacing: 0.2em;	
		}
	}

	&.logout {
		padding-left: 2em;
		&::after {
			content: '';
			position: absolute;
			left: 1em;
			top: 0;
			width: 1.75em;
			height: 100%;
			background-image: url('../../../assets/images/icon-logout.svg');
			background-position: center center;
			background-size: 100% auto;
		}
	}

	&.lobby {
		&::after {
			content: '';
			position: absolute;
			left: 1em;
			top: 0;
			width: 1.56em;
			height: 100%;
			background-image: url('../../../assets/images/icon-lobby.svg');
			background-position: center center;
			background-size: 100% auto;
		}
	}

	&.play {
		&::after {
			content: '';
			position: absolute;
			right: 1.25em;
			top: 0;
			width: 0.67em;
			height: 100%;
			background-image: url('../../../assets/images/icon-play.svg');
			background-position: center center;
			background-size: 100% auto;
		}
	}

	&.video,
	&.audio {
		@include flex('flex-start', 'center');
		padding-left: 1.33em;
		&::after {
			content: '';
			position: absolute;
			right: 1.33em;
			top: 0;
			width: 1.88em;
			height: 100%;
			background-image: url('../../../assets/images/icon-video.svg');
			background-position: center center;
			background-size: 100% auto;
		}
		span {
			font-weight: 700;
			letter-spacing: 0.05em;	
		}
	}

	&.slide {
		span {
			font-weight: 700;
			letter-spacing: 0.05em;	
		}
	}



	&.popup-close {
		width: 2.25em;
		height: 2.25em;
		background-image: url('../../../assets/images/icon-close.svg');
	}

	&.info {
		width: 2.5em;
		height: 2.5em;
		background-image: url('../../../assets/images/icon-info.svg');
		background-size: 2.25em auto;
	}

	&.delete {
		width: 2em;
		height: 2em;
		background-image: url('../../../assets/images/icon-remove.svg');
		background-size: 1.5em auto;
	}

}