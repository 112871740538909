@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Login {
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');

	.Login-logo {
		position: absolute;
		top: 0em;
		left: 0em;
	}
	.Login-wrap {
		position: relative;
		width: 100%;
		.Login-facilitator,
		.Login-group {
			position: relative;
			width: 23.56em;
			height: 25.6em;
			margin: 2em;
		}
	
		@include flex('center', 'center');
	}
}