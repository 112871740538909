@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.PdfGuide {
	height: 100%;	
	text-align: center;
	padding: 2em 0;
	color: white;
	.PdfGuide-noPrint {
		display: none;
		span {
			font-size: 2em;
		}
	}
	.PdfGuide-wrap {
		display:inline-block;
		height: calc(100% - 3em);
		overflow-y: auto;
		overflow-x: hidden;
		@include custom-scrollbar(0.5em, white, #ef571d, 0);
	}
	.PdfGuide-navigation {
		@include flex('center', 'center');
		height: 3em;
		.PdfGuide-navBtn {
			width: 3em;
			height: 100%;
			cursor: pointer;
			&.disabled {
				@include opacity(0.3);
				cursor: not-allowed;
			}

			&.first {
				background-image: url('../../assets/images/buttons/nav-first.svg');
			}
			&.last {
				background-image: url('../../assets/images/buttons/nav-last.svg');
			}

			&.prev {
				background-image: url('../../assets/images/buttons/nav-prev.svg');
			}
			&.next {
				background-image: url('../../assets/images/buttons/nav-next.svg');
			}
			&.prev-skip {
				background-image: url('../../assets/images/buttons/nav-prev-skip.svg');
			}
			&.next-skip {
				background-image: url('../../assets/images/buttons/nav-next-skip.svg');
			}
		}
		.PdfGuide-navPage {
			width: 15em;
			span {
				color: #ef571d;
				font-size: 1.5em;
				line-height: 1;
			}
		}
	}
}


@media print {
	.PdfGuide {
		background-color: white;
		color: black;
		@include flex('center', 'center');
		.PdfGuide-noPrint {
			display: block;
		}
		.PdfGuide-wrap,
		.PdfGuide-navigation {
			display: none;
		}
	}
}