@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PdfMaker {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 3.25em 11.25em 1em 11.25em;
	background-color: #F2F3F3;
	
	.PdfMaker-title {
		text-align: center;
		span {
			font-size: 2.3em;
			font-weight: 600;
			color: #292929;
		}
	}
	.PdfMaker-text {
		text-align: center;
		margin-top: 0.5em;
		p {
			margin-top: 0;
			font-size: 1.7em;
			line-height: 1.2;
		}
	}
	.PdfMaker-textFields {
		position: relative;
		.PdfMaker-textField {
			position: absolute;	
			width: 21.4em;
			.PdfMaker-textFieldTitle {
				text-align: center;
				margin-bottom: 0.56em;
				span {
					font-size: 1.5em;
					font-weight: 600;
					text-transform: uppercase;
				}
			}
			.PdfMaker-textFieldInput {
				width: 100%;
				height: 9em;
				border: 0.11em solid #EF571D;
				textarea {
					resize: none;
					display: inline-block;
					width: 100%;
					height: 100%;
					color: #292929;
					background-color: #FCE5DC;
					font-size: 1.44em;
					padding: 0.6em;
					@include placeholderColor(rgba(#292929, 0.4));
				}
			}
		}
	}
	

	.PdfMaker-line {
		position: absolute;
		width: 10.1em;
		height: 0;
		border-top: 0.11em solid #EF571D;
	}
	.PdfMaker-printComponent {
		display: none;
	}

	.PdfMaker-makePdfBtn {
		position: absolute;
		bottom: 1em;
		left: calc(50% - 5.625em);
	}

	.PdfMaker-infoBtn {
		position: absolute;
		top: 1em;
		right: 5em;
	}	

	&.m6-thought-traps {
		padding: 2.25em 11.25em 1em 11.25em;
		.PdfMaker-textFields {
			margin-top: 3em;
			height: 30em;
			.PdfMaker-textField { 
				&.thoughts {
					top: 0;
					left: 0;
				}
				&.feelings {
					top: 0;
					left: 36.3em;
				}
				&.body {
					top: 18.8em;
					left: 18.15em;
				}
				&.action {
					top: 18.8em;
					left: 54.45em;
				}
			}
			.PdfMaker-line {
				top: 16em;
				&.line-1 {
					left: 14em;
					@include rotate(45deg);
				}
				&.line-2 {
					left: 34em;
					@include rotate(-45deg);
				}
				&.line-3 {
					left: 50.3em;
					@include rotate(45deg);
				}
			}
		}
		
	}

	&.m7-thought-training {
		padding: 2.25em 10em 1em 10em;
		
		.PdfMaker-textFields {
			margin-top: 4em;
			height: 30em;
			.PdfMaker-textField { 
				&.situation {
					top: 7.7em;
					left: 0;
				}
				&.negative-thought {
					top: 0;
					left: 28.2em;
				}
				&.negative-feelings-and-action {
					top: 0em;
					left: 56.5em;
				}
				&.positive-thought {
					top: 15.4em;
					left: 28.2em;
				}
				&.positive-feelings-and-action {
					top: 15.4em;
					left: 56.5em;
				}
			}
			.PdfMaker-line {
				&.line-1 {
					width: 6.3em;
					left: 21.8em;
					top: 10em;
					@include rotate(-45deg);
				}
				&.line-2 {
					width: 5.3em;
					left: 50.5em;
					top: 6.5em;
				}
				&.line-3 {
					width: 6.3em;
					left: 21.8em;
					top: 18em;
					@include rotate(45deg);
				}
				&.line-4 {
					width: 5.3em;
					left: 50.5em;
					top: 21.9em;
				}
			}
		}
	}
}