@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.MatchDndItem {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #EF571D;
	cursor: move;
	&.locked {cursor: not-allowed;}
	.MatchDndItem-text {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 1.25em 1.75em;
		@include flex('center', 'center');
		span {
			font-size: 1.44em;
			color: white;
		}
	}
}