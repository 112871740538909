@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Logo {
	width: 19.5em;
	height: 8.5em;
	background-color: #2C9C84;
	background-image: url('../../../assets/images/logo.svg');
	background-size: 13em auto;
	background-position: top 2em left 4em;
	&.blue {background-color: $blue;}
	&.green {background-color: $green;}
	&.pink {background-color: $pink;}
	&.purple {background-color: $purple;}
	&.yellow {background-color: $yellow;}
}