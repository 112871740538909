@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GamePage {
	position: relative;
	height: 100%;
	padding: 8.5em 2em 2em 2em;
	background-color: #2C9C84;
	background-image: url('../../assets/images/backgrounds/background.svg');
	background-size: cover;
	&.blue {background-color: $blue;}
	&.green {background-color: $green;}
	&.pink {background-color: $pink;}
	&.purple {background-color: $purple;}
	&.yellow {background-color: $yellow;}
	.GamePage-logo {
		position: absolute;
		top: 0em;
		left: 0em;
	}

	.GamePage-wrap {
		position: relative;
		width: 102.55em;
		height: 100%;
		background-color: #F2F3F3;
		padding: 1.5em;
		margin: auto;
		@include flex('center', 'center', 'column');

		.GamePage-backgroundSwirl {
			position: absolute;
			width: 100%;
			height: 100%;
			svg {
				position: absolute;
				top: 5.822em;
				right: 3.78em;
				width: 44.5em;
				height: auto;
			}
			&.blue {svg {fill: $blue;}}
			&.green {svg {fill: $green;}}
			&.yellow {svg {fill: $yellow;}}
			&.pink {svg {fill: $pink;}}
			&.purple {svg {fill: $purple;}}
		}

		.GamePage-menu {
			position: absolute;
			left: 1.5em;
			top: 1.5em;
			width: 22em;
		}

		.GamePage-logoutBtn,
		.GamePage-lobbyBtn {
			position: absolute;
			left: 1.5em;
			bottom: 1.5em;
		}

		.GamePage-page {
			position: absolute;
			top: 1.5em;
			right: 1.5em;
			bottom: 1.5em;
			width: calc(100% - 25em);
			padding: 0 2em 2em 2em;
			overflow: auto;
			@include custom-scrollbar(0.5em, #FCE5DC, #EF571D, 0.5em);

			.GamePage-content {
				position: relative;
				width: 51em;
				background-color: white;
				border: 0.15em solid #EF571D;
				color: #292929;
				padding-right: 0.5em;
				
				.GamePage-title {
					color: #EF571D;
					font-size: 1.5em;
					margin-bottom: 0.25em;
				}
				.GamePage-icons {
					float: right;
					width: 8em;
					padding: 1em 0;
					@include flex('center', 'center', 'column');
				}
				.GamePage-text {
					width: calc(100% - 8em);
					padding: 1.5em 0.5em 1.5em 1em;
					float: left;
					p {
						font-size: 1.5em;
						line-height: 1.25;
						&:nth-child(1) {margin-top: 0;}
						a {
							color: inherit;
							text-decoration: none;
						}
						span {
							font-size: 1.33em;
							font-weight: bold;
							&.illustration {
								display: block;
								&.tanketraening-1-2-3 {
									height: 3em;
									background-image: url('../../assets/images/illustrations/tanketraening-1-2-3.svg');
								}
								&.eks-negativ-tanke {
									height: 5.5em;
									background-image: url('../../assets/images/illustrations/eks-negativ-tanke.svg');
								}
								&.eks-brugbar-tanke {
									margin: 0.75em 0;
									height: 5.5em;
									background-image: url('../../assets/images/illustrations/eks-brugbar-tanke.svg');
								}
								&.eksempel {
									height: 5.5em;
									background-image: url('../../assets/images/illustrations/eksempel.svg');
								}
								&.eksempel-1 {
									height: 8em;
									background-image: url('../../assets/images/illustrations/eksempel-1.svg');
								}
							}
						}
					}
					ul, ol {
						font-size: 1.5em;
						line-height: 1.25;
					}
					.GamePage-illustration {
						width: 100%;
						&.long-term-plan-of-action {
							height: 5em;
							background-image: url('../../assets/images/illustrations/langsigtet-handleplan.svg');
						}
						&.firkanten {
							width: 15em;
							height: 15em;
							background-image: url('../../assets/images/illustrations/firkanten.svg');
						}
					}
					.GamePage-minigamesToggleBtn {
						margin-top: 4em;
						margin-bottom: 1em;
					}
					.GamePage-minigames {
						@include flex('flex-start', 'center');
						> div {margin-right: 1em;}
					}
				}

				.GamePage-buttons {
					padding: 1em;
					@include flex('space-between', 'center');
					&.single {
						@include flex('center', 'center');
					}
					&.many {
						padding-right: 18em;
						@include flex('space-between', 'space-between', 'row');
						flex-wrap: wrap;
						-webkit-flex-wrap: wrap;
						.Button {
							margin-bottom: 1em;
						}
					}
					&.cases {
						padding-right: 1em;
						:nth-child(4),
						:nth-child(5),
						:nth-child(6) {
							margin-top: 2em;
						}
						
						&.open {
							:nth-child(n+4) {
								display: flex;
							}
						}
						&.groupView {
							:nth-child(n+4) {
								display: none;
							}
						}
					}
					
				}
				
			}
		}
	}	
}
