@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
	height: 100%;
	background-color: #2C9C84;
	background-image: url('../assets/images/backgrounds/background.svg');
	background-size: cover;
}