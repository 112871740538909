@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Game {
	position: relative;
	height: 100%;
	padding: 8.5em 2em 2em 2em;

	.Game-logo {
		position: absolute;
		top: 1em;
		left: 2em;
	}

	.Game-code {
		position: absolute;
		top: 1.5em;
		right: 4em;
		span {
			font-size: 1.5em;
			color: white;
			text-transform: uppercase;
			&:nth-child(2) {
				margin-left: 1em;
				padding: 0.25em 0.5em;
				border: 1px solid white;
			}
		}
	}

	.Game-content {
		position: relative;
		width: 102.55em;
		height: 100%;
		background-color: #F2F3F3;
		background-image: url('../../assets/images/backgrounds/background-game.svg');
		background-size: 44.5em auto;
		background-position: top 5.822em right 3.78em;
		padding: 1.5em;
		margin: auto;
		@include flex('center', 'center', 'column');

		.Game-page {
			position: absolute;
			top: 1.5em;
			right: 1.5em;
			bottom: 1.5em;
			width: calc(100% - 25em);
		}
	}

	
}
