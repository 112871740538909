@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.MatchDndPreview {
	.MatchDndPreview-item { 
		position: relative;
		width: 21.4em;
		height: 7.8em;
		background-color: #EF571D;
		transform-origin: center center;
		@include rotate(-7deg);
		.MatchDndPreview-text {
			position: relative;
			width: 100%;
			height: 100%;
			padding: 1.25em 1.75em;
			@include flex('center', 'center');
			span {
				font-size: 1.44em;
				color: white;
			}
		}
	}
}