@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SortDndContainer {
	position: relative;
	width: 100%;
	height: 30em;
	background-image: url('../../../assets/images/frames/frame-sort-category.svg');
	padding: 3em 0.5em 2em 1em;
	&.hover {
		background-image: url('../../../assets/images/frames/frame-sort-category-hover.svg');
	}
	.SortDndContainer-wrap {
		width: 100%;
		height: 100%;
		overflow: auto;
		@include hide-scrollbar();
	}

	&.alarm {
		.SortDndContainer-wrap {
			background-image: url('../../../assets/images/backgrounds/alarm-brain.svg');
			background-size: 11.4em;
		}
	}
	&.think {
		.SortDndContainer-wrap {
			background-image: url('../../../assets/images/backgrounds/thinking-brain.svg');
			background-size: 11.6em;
		}
	}
}