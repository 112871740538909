@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Menu {
	position: relative;
	.Menu-title {
		width: 100%;
		height: 2.5em;
		margin-bottom: 1.25em;
		@include flex('space-between', 'center');
		span {
			display: inline-block;
			line-height: 1;
			margin-top: 0.25em;
			text-transform: uppercase;
			font-size: 2em;
			color: #292929;
			font-weight: 700;
		}
		svg {
			height: 2.5em;
			width: auto;
		}
		&.blue {
			svg {fill: $blue;}
		}
		&.green {
			svg {fill: $green;}
		}
		&.pink {
			svg {fill: $pink;}
		}
		&.purple {
			svg {fill: $purple;}
		}
		&.yellow {
			svg {fill: $yellow;}
		}		
	}
	.Menu-item {
		height: 3.7em;
		margin-bottom: 0.67em;
		background-image: url('../../../assets/images/frames/frame-menu-item.svg');
		padding: 0.4em 1.11em 0 1.11em;
		@include flex('flex-start', 'center');
		cursor: pointer;
		span {
			display: inline-block;
			font-size: 1.5em;
			text-transform: uppercase;
		}
		&.big {
			height: 5.66em;
			padding-top: 0.3em;
			background-image: url('../../../assets/images/frames/frame-menu-item-big.svg');
			span {
				line-height: 1.15em;
			}
		}
		&.selected {
			color: white;
			background-image: url('../../../assets/images/frames/frame-menu-item-selected.svg');
			&.big {
				background-image: url('../../../assets/images/frames/frame-menu-item-big-selected.svg');
			}
		}
	}
}