@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Settings {
	height: 100%;
	padding: 10.5em 3.75em 0 3.75em;
	.Settings-logo {
		position: absolute;
		top: 0em;
		left: 0em;
	}
	.Settings-logoutBtn {
		position: absolute;
		right: 3.75em;
		top: 3em;
	}
	.Settings-adminBtn {
		position: absolute;
		right: 3.75em;
		top: 7em;
	}
	
	.Settings-title {
		font-size: 3.5em;
		color: white;
		margin-bottom: 0.5em;
		text-align: center;
	}

	.Settings-createGameBtn {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 1.5em;
	}

	.Settings-resourcesBtn {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 1.5em;
		margin-left: 1.5em;
	}


	.Settings-info {
		position: absolute;
		right: 2em;
		width: 18em;
		background: white;
		padding:1em;
		p {
			margin-bottom: 0;
			font-size: 1.44em;
			line-height: 1.3;
			&:first-of-type {margin-top: 0;}
		}
	}

	.Settings-games {
		width: 80em;
		max-height: 35em;
		overflow: auto;
		.Settings-game {
			display: inline-block;
			width: 23.56em;
			height: 14.66em;
			margin: 0 1.5em 1.5em 0;
			color: #292929;
			
			
			.Settings-gameHeader {
				position: relative;
				height: 3.6em;
				padding-left: 1.5em;
				background-image: url('../../assets/images/backgrounds/background-login-header.svg');
				@include flex('space-between', 'center');
				.Settings-gameTitle{
					color: white;
					font-size: 1.5em;
					font-weight: 600;
					white-space: nowrap !important;           
					overflow: hidden;         
					word-wrap: normal !important;
					text-overflow: ellipsis !important;
					-ms-text-overflow: ellipsis !important;
				}
				.Settings-deleteBtn {
					position: absolute;
					top: 0em;
					right: 1em;
					width: 2em;
					height: 100%;
					background-image: url('../../assets/images/icon-trash.svg');
					background-size: 1.5em auto;
					background-position: center center;
					background-repeat: no-repeat;
					cursor: pointer;
					&:hover {
						background-size: 1.75em auto;
					}
				}
			}
			.Settings-gameBody {
				position: relative;
				width: 23.3em;
				height: calc(100% - 3.6em);
				margin: -0.2em auto 0 auto;
				padding: 1.89em 1.5em 0 1.5em;
				background-color: white;
				.Settings-playBtn {
					width: 100%;
					margin-top: 1em;
				}

				.Settings-gameCode,
				.Settings-gameDate {
					color: #292929;
					font-weight: normal;
					font-size: 1.44em;
					line-height: 1.3em;
					span {
						width: 5em;
						display: inline-block;
						vertical-align: middle;
					}
				}

				
			}

		}
	}
}