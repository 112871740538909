@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SortDndItem {
	display: inline-block;
	vertical-align: middle;
	width: 21em;
	height: 8.5em;
	margin-right: 1em;
	border-radius: 2.25em;
	background-color: #EF571D;
	will-change: transform;

	padding: 1.5em;
	touch-action: none;
	white-space: normal;
	@include no-select();
	&:nth-last-child(1) {margin-right: 0;}
	span {
		color: white;
		font-size: 1.5em;
		overflow-wrap: break-word;
	}
	&.placed {
		margin-bottom: 1em;
	}
	&.draggable {
		cursor: move;
	}
	&.completed {
		cursor: not-allowed;
	}
	&.animateCorrect {
		background-color: #159F90;
		@include animateCorrect();
	}
	&.animateWrong {
		background-color: #CC002F;
	}

}