@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.BrainstormFacilitator {
	position: relative;
	padding: 1.25em 3.5em 1em 3.5em;
	background-color: #F2F3F3;
	height: 100%;
	.BrainstormFacilitator-part1 {
		.BrainstormFacilitator-categories {
			margin-bottom: 1em;
			@include flex('space-between', 'flex-start');
			.BrainstormFacilitator-category {
				position: relative;
				width: 46.5em;
				height: 45em;
				padding: 4em 3em 3em 1em;
				background-image: url('../../../assets/images/frames/frame-brainstorm-category.svg');
				.BrainstormFacilitator-categoryTitle {
					text-align: center;
					text-transform: uppercase;
					span {
						font-size: 1.5em;
						color: #292929;
					}
				}
				.BrainstormFacilitator-categorySubmissions {
					width: 100%;
					height: calc(100% - 3em);
					margin-top: 1em;
					overflow: auto;
					@include hide-scrollbar();
					.BrainstormFacilitator-categorySubmission {
						position: relative;
						display: inline-block;
						max-width: 15em;
						margin: 1em;
						padding: 1em;
						color: white;
						background-color: #EF571D;
						.BrainstormFacilitator-removeSubmissionBtn {
							position: absolute;
							right: -0.75em;
							top: -0.75em;
							width: 1.67em;
							height: 1.67em;
							background-image: url('../../../assets/images/icon-remove.svg');
							cursor: pointer;
						}
					}
				}	
			}
		}
		.BrainstormFacilitator-nextPartBtn {
			@include flex('center');
		}
	}

	.BrainstormFacilitator-part2 { 
		padding-top: 2em;
		.BrainstormFacilitator-title {
			text-align: center;
			text-transform: uppercase;
			span {
				font-size: 1.5em;
				font-weight: 600;
				color: #292929;
			}
		}
		.BrainstormFacilitator-submissions {
			height: 41em;
			margin-bottom: 1em;
			padding: 1em 15em;
			overflow: auto;
			@include hide-scrollbar();
			.BrainstormFacilitator-submission {
				position: relative;
				width: 40em;
				min-height: 7em;
				margin: 1em;
				padding: 1.5em 4.5em 1.5em 2.5em;
				color: white;
				background-color: #EF571D;
				border-radius: 2.25em;
				float: left;
				&:nth-child(even) {float: right;}
				span {
					color: white;
					font-size: 1.5em;
				}
				.BrainstormFacilitator-removeSubmissionBtn {
					position: absolute;
					right: 1em;
					top: 0.75em;
					width: 1.67em;
					height: 1.67em;
					background-image: url('../../../assets/images/icon-remove.svg');
					cursor: pointer;
				}


			}
		}

		.BrainstormFacilitator-buttons {
			@include flex('center');
		}
		.BrainstormFacilitator-closeGameBtn {
			margin-left: 2em;
		}
		.BrainstormFacilitator-nextBtn {
			margin-left: 2em;
		}
	}

	
}