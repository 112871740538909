@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ResetPassword {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	.ResetPassword-title {
		width: 100%;
		height: 3.59em;
		background-image: url('../../assets/images/backgrounds/background-login-header.svg');
		@include flex('flex-start', 'center');
		padding-left: 1.25em;
		span {
			font-size: 1.4em;
			font-weight: 600;
			color: white;
			text-transform: uppercase;
			white-space: nowrap;
		}
	}
	.ResetPassword-body {
		width: 23.28em;
		height: calc(100% - 3.59em);
		background-color: white;
		margin: -0.2em auto 0 auto;
		padding: 1.7em 1.5em 0 1.5em;
		.ResetPassword-form {
			position: relative;
			input {
				display: block;
				font-size: 1.11em;
				line-height: 1;
				width: 100%;
				height: 3em;
				border: 1px solid #EF571D;
				border-radius: 0.3em;
				padding: 1em;
				margin: auto;
				margin-bottom: 0.9em;
				background-color: #FCE5DC;
				color: #292929;
				font-weight: normal;
				&::placeholder {color: rgba(#292929, 0.3);}
			}
			.ResetPassword-buttons {
				@include flex('space-between', 'center');
				> div { 
					margin: 0 0.5em;
					&:nth-child(1) {margin-left: 0;}
					&:nth-last-child(1) {margin-right: 0;}
				}			
				
			}
			.ResetPassword-loginBtn {
				width: 100%;
			}
	
			
			.ResetPassword-errorMessage {
				height: 1.5em;
				margin-bottom: 0.6em;
				padding: 0 1em;
				font-size: 0.85em;
				color: #EF571D;
				text-align: center;
			}
		}	

		.ResetPassword-info {
			margin-bottom: 1.5em;
			span {
				font-size: 1.1em;
				color: #292929;
			}
		}
		.ResetPassword-resetPasswordBtn,
		.ResetPassword-createUserBtn {
			color: #EF571D;
			text-decoration: none;
			cursor: pointer;
			margin-bottom: 0.5em;
			span {
				font-size: 1.11em;
			}
		}
	}
	
}