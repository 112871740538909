@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.OrganizeDndContainer {
	position: relative;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	&.all {height: auto;}
	&.hover:not(.all) {
		border-radius: 2.25em;
		background-color: rgba(#EF571D, 0.4);
	}
}