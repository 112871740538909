@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Icon {
	position: relative;
	width: 100%;
	margin: 0.5em;
	&.alarm-brain {
		height: 8em;
		background-image: url('../../../assets/images/icons/alarm-brain.svg');
	}
	&.analog-teaching {
		height: 8.2em;
		background-image: url('../../../assets/images/icons/analog-teaching.svg');
	}
	&.attention {
		height: 8.2em;
		background-image: url('../../../assets/images/icons/attention.svg');
	}
	&.calm {
		height: 7.7em;
		background-image: url('../../../assets/images/icons/calm.svg');
	}
	&.communication {
		height: 8.1em;
		background-image: url('../../../assets/images/icons/communication.svg');
	}
	&.idea-round {
		height: 8.1em;
		background-image: url('../../../assets/images/icons/idea-round.svg');
	}
	&.making-decisions {
		height: 8.2em;
		background-image: url('../../../assets/images/icons/making-decisions.svg');
	}
	&.online-teaching {
		height: 7em;
		background-image: url('../../../assets/images/icons/online-teaching.svg');
	}
	&.plan-of-action {
		height: 8.2em;
		background-image: url('../../../assets/images/icons/plan-of-action.svg');
	}
	&.problem-solving {
		height: 8em;
		background-image: url('../../../assets/images/icons/problem-solving.svg');
	}
	&.thinking-brain {
		height: 7.7em;
		background-image: url('../../../assets/images/icons/thinking-brain.svg');
	}
	&.thoughts-helping {
		height: 9.4em;
		background-image: url('../../../assets/images/icons/thoughts-helping.svg');
	}
	&.toolbox {
		height: 8.6em;
		background-image: url('../../../assets/images/icons/toolbox.svg');
	}
}