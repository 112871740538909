@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.DeleteUserPopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#004E3E, 0.5);
	backdrop-filter: blur(1px);
	z-index: 5;
	@include flex('center', 'center');
	.DeleteUserPopup-content {
		position: relative;
		width: 46em;
		background-color: white;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba(#000000, 0.2));
		.DeleteUserPopup-header {
			height: 4em;
			background-color: #EF571D;
			@include flex('center', 'center');
			.DeleteUserPopup-title {
				color: white;
				
				margin-bottom: 0.25em;
				span {
					font-size: 1.5em;
					font-weight: bold;
				}
			}
		}
		.DeleteUserPopup-body {
			padding: 1.5em 2em;
			.DeleteUserPopup-text {
				p {
					color: #292929;
					font-size: 1.67em;
					line-height: 1.33;
					&:nth-child(1) {margin-top: 0;}
					a {
						color: #292929;

					}
				}
			}
	
			.DeleteUserPopup-buttons {
				width: 100%;
				margin-top: 1em;
				> div {margin: 0 1em;}
				@include flex('center', 'center');
			}
			.DeleteUserPopup-errMsg {
				height: 2em;
				span {
					font-size: 1.2em;
					color: #EF571D;
				}
			}
		}
	}
}