@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SortDndDragLayer {
	.SortDndDragLayer-item {
		display: inline-block;
		vertical-align: middle;
		width: 22em;
		height: 7.5em;
		margin-right: 1em;
		border-radius: 2.25em;
		background-color: #EF571D;
		will-change: transform;
		padding: 1.5em;
		touch-action: none;
		white-space: normal;
		@include no-select();
		&:nth-last-child(1) {margin-right: 0;}
		span {
			color: white;
			font-size: 1.5em;
		}
		transform-origin: center center;
		@include rotate(-7deg);
	}
}