@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupMinigame {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	.PopupMinigame-content {
		position: relative;
		width: 102.55em;
		height: calc(100% - 7.6em);
		margin: 5.6em auto 0 auto;
		color: black;
		background-color: #F2F3F3;
		background-image: url('../../../assets/images/backgrounds/background-game.svg');
		background-size: 44.5em auto;
		background-position: top 13em right 2em;
		.PopupMinigame-closeBtn {
			position: absolute;
			top: 1em;
			right: 1em;
		}
	}
	.PopupMinigame-title {
		position: absolute;
		top: 3em;
		left: calc(50% - 48em);
		min-width: 22em;
		padding: 1em;
		background-color: #EF571D;
		span {
			font-size: 1.5em;	
			text-transform: uppercase;
			color: white;
		}
	}

		.PopupMinigame-prevBtn {
			position: absolute;
			left: 1em;
			bottom: 1em;
		}
		.PopupMinigame-nextBtn {
			position: absolute;
			right: 1em;
			bottom: 1em;
		}

	
}
