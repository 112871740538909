@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Organize {
	height: 100%;
	padding: 4.5em 1em 2em 1.5em;
	background-color: #F2F3F3;

	.Organize-groups {
		@include flex('space-around', 'flex-start');
		.Organize-group {
			width: 24.1em;
			.Organize-groupTitle {
				text-align: center;
				@include no-select();
				span {
					text-transform: uppercase;
					font-size: 1.5em;
					font-weight: 600;
				}
			}
			.Organize-groupWrap {
				width: 100%;
				height: 31em;
				background-image: url('../../../assets/images/frames/frame-organize-container.svg');
				padding: 3.5em 1em 0 1em;
				.Organize-groupDescription {
					height: 7.5em;
					padding: 0 2.25em;
					line-height: 1.2;
					@include no-select();
					span {
						font-size: 1.44em;
					}
				}
				.Organize-groupContainers {
					@include flex('flex-start', 'center', 'column');
					.Organize-container {
						width: 21em;
						height: 7.8em;
						border-radius: 2.25em;
						background-color: rgba(#EF571D, 0.4);
						margin-bottom: 1.33em;
						&:last-child() {margin-bottom: 0;}
					}
				}
			}		
		}
	}

	.Organize-panel {
		width: 100%;
		margin-top: 1.25em;
		@include flex('space-between', 'center');
		.Organize-scrollLeftBtn, 
		.Organize-scrollRightBtn {
			height: 4.5em;
			width: 2.25em;
			bottom: 2em;
			@include opacity(0.25);
			&.active {
				@include opacity(1);
				cursor: pointer;
			}
		}
		.Organize-scrollLeftBtn {
			left: 0;
			background-image: url('../../../assets/images/icon-arrow-left.svg');
		}
		.Organize-scrollRightBtn {
			right: 0;
			background-image: url('../../../assets/images/icon-arrow-right.svg');
		}	
		.Organize-itemsWrap {
			width: calc(100% - 9em);
			height: 7.8em;
			padding: 0 1.5em;
			border-left: 0.25em solid #EF571D;
			border-right: 0.25em solid #EF571D;
			.Organize-items {
				width: 100%;
				overflow: auto;
				white-space: nowrap;
				scroll-behavior: smooth;
				@include hide-scrollbar();					
					
				.Organize-item {
					display: inline-block;
					vertical-align: middle;
					width: 21em;
					height: 9.5em;
					border-radius: 2.25em;
					margin-right: 1em;				
					// will-change: transform;
					// touch-action: none;
					white-space: normal;
					@include no-select();
					&:nth-last-child(1) {margin-right: 0;}
				}
			}
		}
	}

	

	.Organize-okBtn {
		position: absolute;
		bottom: 1em;
		left: calc(50% - 5.625em);
	}

	.Organize-infoBtn {
		position: absolute;
		top: 1em;
		right: 5em;
	}
}