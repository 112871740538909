@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CreateGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#004E3E, 0.6);
	@include flex('center', 'center');

	.CreateGamePopup-content {
		position: relative;
		width: 23.56em;
		.CreateGamePopup-header {
			position: relative;
			z-index: 2;
			width: 100%;
			height: 3.6em;
			padding-left: 1.5em;
			background-image: url('../../assets/images/backgrounds/background-login-header.svg');
			@include flex('flex-start', 'center');
			.CreateGamePopup-title {
				font-size: 1.5em;
				font-weight: 600;
				text-transform: uppercase;
				color: white;
			}
		}	

		.CreateGamePopup-body {
			position: relative;
			z-index: 1;
			width: 23.3em;
			margin: -0.2em auto 0 auto;
			background-color: white;
			padding: 1.5em 1.4em;
			.CreateGamePopup-form {
				.CreateGamePopup-label {
					font-size: 1.11em;
					font-weight: normal;
					margin-bottom: 0.5em;
				}
				input {
					display: block;
					font-size: 1.11em;
					line-height: 1;
					width: 100%;
					height: 3em;
					border: 1px solid #EF571D;
					border-radius: 0.3em;
					padding: 1em;
					margin: auto;
					margin-bottom: 0.9em;
					background-color: #FCE5DC;
					color: #292929;
					font-weight: normal;
					&::placeholder {color: rgba(#292929, 0.4);}
				}
				input:disabled {
					color: black;
					background-color: rgb(226, 225, 225);
				}
				.CreateGamePopup-errMsg {
					color: #EF571D;
				}

			}
			.CreateGamePopup-buttons {
				margin-top: .25em;
				> div:nth-child(2) {
					margin-top: 1.11em;
				}
			}
		}
	}
}