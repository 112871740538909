@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupSlide {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	.PopupSlide-content {
		position: relative;
		width: 102.55em;
		height: calc(100% - 7.6em);
		margin: 5.6em auto 0 auto;
		color: black;
		background-color: #F2F3F3;
		.PopupSlide-closeBtn {
			position: absolute;
			top: 1em;
			right: 1em;
		}
		.PopupSlide-text {
			width: 100%;
			height: 100%;
			background-size: auto 80%;
			background-position: center center;
			overflow-wrap: break-word;
			span {
				font-size: 1.6em;
				text-wrap:wrap;
				line-height: 1.25em;
			}
			&.case-1 {
				padding-left: 24em;
				padding-right: 24em;
				padding-top: 10.5em;
				background-image: url('../../../assets/images/slides/case-1.svg');
			}
			&.case-2 {
				padding-left: 13em;
				padding-right: 16em;
				padding-top: 13.5em;
				background-size: 90em auto;
				background-image: url('../../../assets/images/slides/case-2.svg');
				span {
					font-size: 2.5em;
					line-height: 1.25em;
				}
			}
			&.case-3 {
				padding-left: 18em;
				padding-right: 18em;
				padding-top: 12em;
				background-image: url('../../../assets/images/slides/case-3.svg');
				span {
					font-size: 2.25em;
					line-height: 1.25em;
				}
			}
			&.case-4 {
				padding-left: 13em;
				padding-right: 12em;
				padding-top: 11.5em;
				background-size: 90em auto;
				background-image: url('../../../assets/images/slides/case-4.svg');
				span {
					font-size: 2.6em;
					line-height: 1.25em;
				}
			}
			&.case-5 {
				padding-left: 13em;
				padding-right: 12em;
				padding-top: 12.5em;
				background-size: 90em auto;
				background-image: url('../../../assets/images/slides/case-5.svg');
				span {
					font-size: 2.6em;
					line-height: 1.25em;
				}
			}
			&.case-6 {
				padding-left: 21em;
				padding-right: 17em;
				padding-top: 11.5em;
				background-image: url('../../../assets/images/slides/case-6.svg');
				span {
					font-size: 2.1em;
					line-height: 1.25em;
				}
			}
			&.case-7 {
				padding-left: 23.5em;
				padding-right: 22em;
				padding-top: 11em;
				background-image: url('../../../assets/images/slides/case-7.svg');
				span {
					font-size: 1.9em;
					line-height: 1.25em;
				}
			}
		}
		.PopupSlide-image {
			width: 100%;
			height: 100%;
			background-size: auto 80%;
			background-position: center center;
			&.idea-round {
				background-image: url('../../../assets/images/slides/iderunde.svg');
			}
			&.making-decisions {
				background-image: url('../../../assets/images/slides/beslutninger.svg');
			}
			&.plan-of-action {
				background-image: url('../../../assets/images/slides/handleplansskema.svg');
			}
			&.problem-solving {
				background-image: url('../../../assets/images/slides/problemloesningsskema.svg');
			}
			&.quotes {
				background-image: url('../../../assets/images/slides/quotes.svg');
			}			
			&.stroop-1 {
				background-image: url('../../../assets/images/slides/stroop-1.svg');
			}
			&.stroop-2 {
				background-image: url('../../../assets/images/slides/stroop-2.svg');
			}
			&.the-bad-circle {
				background-image: url('../../../assets/images/slides/den-daarlige-cirkel.svg');
			}
			&.toolbox {
				background-image: url('../../../assets/images/slides/vaerktoejskasse.svg');
			}
			&.too-many-breaks {
				background-image: url('../../../assets/images/slides/balance-cirkel-1.svg');
			}
			&.too-few-breaks {
				background-image: url('../../../assets/images/slides/balance-cirkel-2.svg');
			}
			&.thought-and-feeling {
				background-image: url('../../../assets/images/slides/thought-and-feeling.svg');
			}

			
			&.exsample-1 {
				background-image: url('../../../assets/images/slides/exsample-1.svg');
			}
			&.exsample-2 {
				background-image: url('../../../assets/images/slides/exsample-2.svg');
			}
			&.thought-trap {
				background-size: 90em auto;
				background-image: url('../../../assets/images/slides/thought-trap.svg');
			}
			


		}
		.PopupSlide-video,
		.PopupSlide-audio {
			width: 100%;
			height: 100%;
			@include flex('center', 'center', 'column');
		}
		.PopupSlide-buttons {
			position: absolute;
			bottom: 1em;
			width: 100%;
			.PopupSlide-prevBtn {
				position: absolute;
				left: 1em;
				bottom: 0;
			}
			.PopupSlide-nextBtn {
				position: absolute;
				right: 1em;
				bottom: 0;
			}

		}
	}
	.PopupSlide-title {
		position: absolute;
		top: 3em;
		left: calc(50% - 48em);
		min-width: 22em;
		padding: 1em;
		background-color: #EF571D;
		span {
			font-size: 1.5em;	
			text-transform: uppercase;
			color: white;
		}
	}
}
