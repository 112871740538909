@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Admin {
	height: 100%;
	padding: 11.5em 3.75em 0 3.75em;

	.Admin-logo {
		position: absolute;
		top: 0em;
		left: 0em;
	}

	.Admin-settingsBtn {
		position: absolute;
		left: 3.75em;
		bottom: 3em;
	}

	.Admin-addUser {
		width: 30em;
		margin-bottom: 3em;
		@include flex('space-between', 'center');
		.Admin-addUserInput {
			input {
				display: block;
				font-size: 1.11em;
				line-height: 1;
				width: 15em;
				height: 2.5em;
				border: 1px solid #EF571D;
				border-radius: 0.3em;
				padding: 1em;
				background-color: white;
				color: #292929;
				font-weight: normal;
				&::placeholder {color: rgba(#292929, 0.4);}
			}
		}
	}

	.Admin-users {
		display: relative;
		width: 40em;
		background-color: white;
		color: #292929;
		overflow: auto;
		.Admin-usersItem {
			width: 20em;
			height: 2.5em;
			padding: 0 1em;
			@include flex('flex-start', 'center');
			&.created {
				width: 15em;
			}
			&.delete {
				width: 5em;
			}
		}
		.Admin-usersHeader {
			background-color: #EF571D;
			color: white;
			font-weight: bold;
			@include flex('space-between', 'center');
		}

		.Admin-usersBody {
			max-height: 30em;
			overflow: auto;
			@include custom-scrollbar(0.5em, #FCE5DC, #EF571D, 0.5em);
			.Admin-usersRow {
				@include flex('space-between', 'center');
			}
		}
	}
}