@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.GameCode {
	position: absolute;
	top: 1.5em;
	right: 4em;
	color: white;
	width: 19em;
	@include flex('space-between', 'center');
	.GameCode-label {
		@include flex('center', 'center');
		span {
			font-size: 1.5em;
			text-transform: uppercase;
		}
	}
	.GameCode-code {
		width: 11.25em;
		height: 3em;
		background-image: url('../../../assets/images/frames/frame-game-code.svg');
		@include flex('center', 'center');
		span {
			font-size: 1.5em;
			text-transform: lowercase;
		}
	}
}