@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Match {
	height: 100%;
	padding: 2.6em 1.5em 1.5em 1.5em;
	background-color: #F2F3F3;
	color: #292929;
	.Match-groups {
		width: 81.4em;
		margin: 0 auto;
		.Match-groupTypes {
			width: 100%;
			margin-bottom: 1.3em;
			@include flex('space-between', 'center');
			.Match-typeName {
				width: 27.13em;
				text-align: center;
				span {
					font-size: 1.5em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
		}
		.Match-group {
			width: 100%;
			height: 13.5em;
			margin-bottom: 1.3em;
			padding: 0 3.1em 0 4.11em;
			background-image: url('../../../assets/images/frames/frame-match-container.svg');
			@include flex('space-between', 'center');
			.Match-itemContainer {
				width: 21.4em;
				height: 7.8em;
				background: rgba(#EF571D, 0.2);
			}
		}
	}

	.Match-panel {
		width: 100%;
		margin-top: 1.25em;
		@include flex('space-between', 'center');
		.Match-scrollLeftBtn, 
		.Match-scrollRightBtn {
			height: 4.5em;
			width: 2.25em;
			bottom: 2em;
			@include opacity(0.25);
			&.active {
				@include opacity(1);
				cursor: pointer;
			}
		}
		.Match-scrollLeftBtn {
			left: 0;
			background-image: url('../../../assets/images/icon-arrow-left.svg');
		}
		.Match-scrollRightBtn {
			right: 0;
			background-image: url('../../../assets/images/icon-arrow-right.svg');
		}	
		.Match-itemsWrap {
			width: calc(100% - 9em);
			height: 7.8em;
			padding: 0 1.5em;
			border-left: 0.25em solid #EF571D;
			border-right: 0.25em solid #EF571D;
			.Match-items {
				width: 100%;
				overflow: auto;
				white-space: nowrap;
				scroll-behavior: smooth;
				@include hide-scrollbar();					
					
				.Match-item {
					display: inline-block;
					vertical-align: middle;
					width: 21.4em;
					height: 7.8em;
					margin-right: 1em;				
					// will-change: transform;
					// touch-action: none;
					white-space: normal;
					@include no-select();
					&:nth-last-child(1) {margin-right: 0;}
				}
			}
		}
	}	

	.Match-okBtn {
		position: absolute;
		bottom: 1em;
		left: calc(50% - 5.625em);
	}

	.Match-infoBtn {
		position: absolute;
		top: 1em;
		right: 5em;
	}
}