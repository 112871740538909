@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Sort {
	height: 100%;
	padding: 2.6em 4em;
	background-color: #F2F3F3;
	.Sort-categories {
		@include flex('space-between', 'flex-start');
		.Sort-category {
			width: 47em;
			.Sort-categoryTitle {
				text-align: center;
				color: #292929;
				font-size: 1.5em;
				text-transform: uppercase;
			}
		}
	}

	.Sort-panel {
		position: absolute;
		left: 1.5em;
		right: 1.5em;
		margin-top: 3em;
		height: 9.5em;
		padding: 0 4em;
		.Sort-scrollLeftBtn, 
		.Sort-scrollRightBtn {
			position: absolute;
			height: 4.5em;
			width: 2.25em;
			top: calc(50% - 2.25em);
			
			@include opacity(0.25);
			&.active {
				@include opacity(1);
				cursor: pointer;
			}
		}
		.Sort-scrollLeftBtn {
			left: 0;
			background-image: url('../../../assets/images/icon-arrow-left.svg');
		}
		.Sort-scrollRightBtn {
			right: 0;
			background-image: url('../../../assets/images/icon-arrow-right.svg');
		}		
		.Sort-itemsWrap {
			width: 100%;
			height: 100%;
			padding: 1em 1em;
			border-left: 0.25em solid #EF571D;
			border-right: 0.25em solid #EF571D;
			.Sort-items {
				white-space: nowrap;
				overflow: auto;
				scroll-behavior: smooth;
				@include hide-scrollbar();
			}
		}
	}

	.Sort-okBtn {
		position: absolute;
		bottom: 1em;
		left: calc(50% - 5.625em);
	}


	.Sort-infoBtn {
		position: absolute;
		top: 0.75em;
		right: 4.75em;
	}
}