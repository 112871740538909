@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Image {
	width: 100%;
	height: 100%;
	background-size: auto 80%;
	background-position: center center;
	&.breaks {
		background-image: url('../../../assets/images/slides/breaks.svg');
	}
	&.conflict-behaviours {
		background-color: #F2F3F3;
		background-image: url('../../../assets/images/slides/conflict-behaviours.svg');
		background-size: 95% auto;
		background-position: top 6em center;
	}
	&.example-decision {
		background-size: 80% auto;
		background-image: url('../../../assets/images/slides/eks-beslutning.svg');
	}
	&.idea-round {
		background-image: url('../../../assets/images/slides/iderunde.svg');
	}
	&.longterm-plan-of-action {
		background-image: url('../../../assets/images/slides/langsigtet-maal.svg');
	}
	&.making-decisions {
		background-image: url('../../../assets/images/slides/beslutninger.svg');
	}
	&.plan-of-action {
		background-image: url('../../../assets/images/slides/handleplansskema.svg');
	}
	&.problem-solving {
		background-image: url('../../../assets/images/slides/problemloesningsskema.svg');
	}
	&.quotes {
		background-size: 70% auto;
		background-image: url('../../../assets/images/slides/quotes.svg');
	}		
	&.rabbit-duck {
		background-size: auto 70%;
		background-image: url('../../../assets/images/slides/kanin-and.svg');
	}
	&.stroop-1 {
		background-image: url('../../../assets/images/slides/stroop-1.svg');
	}
	&.stroop-2 {
		background-image: url('../../../assets/images/slides/stroop-2.svg');
	}
	&.the-bad-circle {
		background-image: url('../../../assets/images/slides/den-daarlige-cirkel.svg');
	}
	&.thought-training-ex-1 {
		background-size: 59.11em auto;
		background-image: url('../../../assets/images/slides/tanketraening-eks-1.svg');
	}
	&.thought-training-ex-2 {
		background-size: 55.9em auto;
		background-image: url('../../../assets/images/slides/tanketraening-eks-2.svg');
	}

	&.toolbox {
		background-image: url('../../../assets/images/slides/vaerktoejskasse.svg');
	}
	&.too-many-breaks {
		background-image: url('../../../assets/images/slides/balance-cirkel-1.svg');
	}
	&.too-few-breaks {
		background-image: url('../../../assets/images/slides/balance-cirkel-2.svg');
	}

	&.thought-and-feeling {
		background-image: url('../../../assets/images/slides/thought-and-feeling.svg');
	}

	&.case-1 {
		background-image: url('../../../assets/images/slides/case-1.svg');
	}
	&.case-2 {
		background-size: 90em auto;
		background-image: url('../../../assets/images/slides/case-2.svg');
	}
	&.case-3 {
		background-image: url('../../../assets/images/slides/case-3.svg');
	}
	&.case-4 {
		background-image: url('../../../assets/images/slides/case-4.svg');
	}
	&.case-5 {
		background-size: 90em auto;
		background-image: url('../../../assets/images/slides/case-5.svg');
	}
	&.case-6 {
		background-image: url('../../../assets/images/slides/case-6.svg');
	}
	&.case-7 {
		background-image: url('../../../assets/images/slides/case-7.svg');
	}
	&.exsample-1 {
		background-image: url('../../../assets/images/slides/exsample-1.svg');
	}
	&.exsample-2 {
		background-image: url('../../../assets/images/slides/exsample-2.svg');
	}
	&.thought-trap {
		background-size: 90em auto;
		background-image: url('../../../assets/images/slides/thought-trap.svg');
	}


	&.audio {
		&.firkanten {
			width: 30em;
			height: 30em;
			background-image: url('../../../assets/images/illustrations/firkanten.svg');
		}
	}
}