@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#004E3E, 0.5);
	backdrop-filter: blur(1px);
	z-index: 5;
	@include flex('center', 'center');
	.Popup-content {
		position: relative;
		width: 46.5em;
		background-color: white;
		padding: 1.5em 2em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba(#000000, 0.2));
		.Popup-title {
			color: #EF571D;
			margin-bottom: 0.25em;
			span {
				font-size: 1.5em;
			}
		}
		.Popup-text {
			p {
				color: #292929;
				font-size: 1.67em;
				line-height: 1.33;
				&:nth-child(1) {margin-top: 0;}
			}
		}

		.Popup-image {
			width: 100%;
			height: 100%;
			background-size: auto 80%;
			background-position: center center;
			&.thinking-examples {
				background-image: url('../../../assets/images/slides/thinking-example.svg');
			}
		}
		.Popup-buttons {
			width: 100%;
			> div {margin: 0 1em;}
			@include flex('center', 'center');
			// &.single {
			// }
		}

		
	}

	&.intro,
	&.intro-small,
	&.intro-smallMedium,
	&.intro-medium,
	&.intro-big {
		.Popup-content {
			height: 31.75em;
			padding: 5em 5.5em;
			background-image: url('../../../assets/images/frames/frame-popup.svg');
			background-size: 43em auto;
			.Popup-buttons {
				position: absolute; 
				bottom: 5em;
				left: 0;
			}
		}
	}
	&.intro-small {
		.Popup-content {
			width: 30em;
			height: 20.8em;
			background-size: 28.8em auto;
			.Popup-buttons {
				bottom: 3em;
			}
		}
	}
	&.intro-smallMedium {
		.Popup-content {
			width: 40em;
			height: 27.7em;
			background-size: 38.4em auto;
			.Popup-buttons {
				bottom: 3em;
			}
		}
	}
	&.intro-medium {
		.Popup-content {
			width: 50em;
			height: 34.7em;
			background-size: 48em auto;
			.Popup-buttons {
				bottom: 3em;
			}
		}
	}
	&.intro-big {
		.Popup-content {
			width: 75em;
			height: 52em;
			background-size: 72em auto;
			.Popup-buttons {
				bottom: 3em;
			}
		}
	}

	&.image {
		.Popup-content {
			width: 102.55em;
			height: calc(100% - 7.6em);
			padding: 0;
			background-image: none;
			.Popup-buttons {
				bottom: 1em;
			}
		}
		
	}
}
