@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DeleteGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#004E3E, 0.6);
	@include flex('center', 'center');

	.DeleteGamePopup-content  {
			position: relative;
			width: 23.56em;		
			.DeleteGamePopup-header {
				position: relative;
				z-index: 2;
				width: 100%;
				height: 3.6em;
				padding-left: 1.5em;
				background-image: url('../../assets/images/backgrounds/background-login-header.svg');
				@include flex('flex-start', 'center');
				
				.DeleteGamePopup-title {
					position: relative;
					z-index: 1;
					font-size: 1.5em;
					font-weight: 600;
					text-transform: uppercase;
					color: white;
				}
			}	
	
			.DeleteGamePopup-body {
				width: 23.3em;
				margin: -0.2em auto 0 auto;
				background-color: white;
				padding: 1.5em 1.4em;
				.DeleteGamePopup-text {
					font-size: 1.11em;
					color: #292929;
				}
				.DeleteGamePopup-buttons {
					margin-top: 1em;
					> div:nth-child(2) {
						margin-top: 1.11em;
					}
				}
			}
	}
}