@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.OrganizeDndItem {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 2.25em;
	background-color: #EF571D;// EF571D
	&.completed {background-color: #2C9C84;}
	cursor: move;
	.locked {cursor: not-allowed;}
	.OrganizeDndItem-text {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 1.25em 1.75em;
		@include flex('center', 'center');
		span {
			font-size: 1.44em;
			color: white;
		}
	}
}