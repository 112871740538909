@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameOverview {
	position: relative;
	height: 100%;
	padding: 8em 2em 0 2em;

	.GameOverview-logo {
		position: absolute;
		top: 0em;
		left: 0em;
		z-index: 1;
	}


	.GameOverview-modules {
		position: relative;
		width: 103em;
		height: 52em;
		margin: 0 auto;
		background-color: #F2F3F3;
		background-image: url('../../assets/images/backgrounds/background-swirl-grey.svg');
		background-size: 44.4em auto;
		background-position: right 3.75em bottom 1.75em;
		padding: 2em 1.5em;
		z-index: 2;
		.GameOverview-module {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 23.25em;
			height: 20.83em;
			margin-right: 1.5em;
			margin-bottom: 1.5em;
			border: 0.25em solid transparent;
			background-color: #F2F3F3;
			box-sizing: content-box;
			&:nth-child(4), 
			&:nth-child(8) {
				margin-right: 0;
			}
			
			.GameOverview-moduleHeader {
				position: relative;
				width: 100%;
				height: 3.25em;
				padding: 0 1.25em;
				@include flex('space-between', 'center');
				span {
					text-transform: uppercase;
					font-size: 1.5em;
					font-weight: 600;
					letter-spacing: 0.2em;
				}
				svg {
					width: 3em;
					height: auto;
				}
			}


			.GameOverview-moduleBody {
				width: 100%;
				height: calc(100% - 3.6em);
				padding: 0em 1.25em;
				background-color: #F2F3F3;
				.GameOverview-moduleDescription {
					p {
						color: #292929;
						font-size: 1.44em;
						line-height: 1.15;
						margin: 0;
					}
				}
				.GameOverview-moduleBtn {
					position: absolute;
					left: 0;
					bottom: 1.25em;
					width: 100%;
					@include flex('center', 'center');
				}
			}

			&.blue {
				border-color: $blue;
				.GameOverview-moduleHeader {
					span {color: $blue;}
					svg {fill: $blue;}
				}
			}
			&.green {
				border-color: $green;
				.GameOverview-moduleHeader {
					span {color: $green;}
					svg {fill: $green;}
				}
			}
			&.pink {
				border-color: $pink;
				.GameOverview-moduleHeader {
					span {color: $pink;}
					svg {fill: $pink;}
				}
			}
			&.purple {
				border-color: $purple;
				.GameOverview-moduleHeader {
					span {color: $purple;}
					svg {fill: $purple;}
				}
			}
			&.yellow {
				border-color: $yellow;
				.GameOverview-moduleHeader {
					span {color: $yellow;}
					svg {fill: $yellow;}
				}
			}
		}
		.GameOverview-lobbyBtn {
			position: absolute;
			left: 1.5em;
			bottom: 1.5em;
		}
	
	}

}
