@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Loading {
	height: 100%;
	@include flex('center', 'center');
	
	.Loading-content {
		.Loading-circles {
			width: 100%;
			@include flex('center', 'center');
			.Loading-circle {
				width: 4em;
				height: 4em;
				margin: 0 0.5em;
				background-color: rgba(#EF571D, 1);
				border-radius: 2em;
			}
		}
		.Loading-text {
			margin-top: 1em;
			color: #EF571D;
		}
	}
	
}

@for $i from 1 to 4 {
	.Loading-circle:nth-child(#{$i}) {
		animation: loading 600ms ease #{300 * $i}ms alternate infinite;
	}
}
@keyframes loading {
	to {
		background-color: rgba(#EF571D, 0.1);
	}
}

@media only screen and (max-aspect-ratio: 1/1) {
	.Loading .Loading-panel {
		width: 100%;
	}
}