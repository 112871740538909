@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Dialogue {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 3.25em 15em 1em 15em;
	background-color: #F2F3F3;
	.Dialogue-dialogue {
		position: relative;
		width: 100%;
		height: 32.5em;
		background-image: url('../../../assets/images/frames/frame-dialogue.svg');
		.Dialogue-lines {
			position: absolute;
			left: 1em;
			bottom: 1.25em;
			right: 3.5em;
			top: 2em;
			// max-height: calc(100% - 1em);
			padding: 0 1.55em;
			overflow: auto;
			display: table-cell; 
			vertical-align: bottom;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
			@include hide-scrollbar();
			.Dialogue-line {
				position: relative;
				padding-bottom: 2.5em;
				@include flex('flex-start', 'center');
				.Dialogue-personName {
					width: 6.5em;
					height: 6.5em;
					border-radius: 100%;
					background-color:#969797;
					@include flex('center', 'center');
					span {
						color: white;
						text-transform: uppercase;
						font-size: 1.5em;
					}
				}
				.Dialogue-bubble {
					position: relative;
					width: 35.25em;
					color: white;
					background-color:#969797;
					padding: 2em;
					border-radius: 2.25em;
					text-align: left;
					margin-left: 3em;
					span {
						font-size: 1.5em;
					}
				}
				&.placeholder {
					margin: 0;
					padding: 0;
					height: 20em;
				}

				&.person-2 {
					@include flex('flex-start', 'center', 'row-reverse');
					.Dialogue-personName {
						background-color: #EF571D;
					}
					.Dialogue-bubble {
						background-color: #EF571D;
					}

				}

				&.dialogue {
					&.person-1 {
						.Dialogue-bubble {
							&::after {
								content: '';
								position: absolute;
								top: calc(50% - 1em);
								left: -1.7em;
								border-right: 1.75em solid #969797;
								border-bottom: 1em solid transparent;
								border-top: 1em solid transparent;
							}
						}
					}
					&.person-2 {
						.Dialogue-bubble {
							margin: 0 3em 0 0;
							&::after {
								content: '';
								position: absolute;
								top: calc(50% - 1em);
								right: -1.7em;
								border-left: 1.75em solid #EF571D;
								border-bottom: 1em solid transparent;
								border-top: 1em solid transparent;
							}
						}
						.Dialogue-personName {
							left: unset;
							right: 0;
						}
					}
				}

				&.action {
					@include flex('center', 'center');
					.Dialogue-bubble {
						width: 100%;
						margin: 0 3em;
						color: #292929;
						background-color:white;
						border: 1px solid #EF571D;
					}
				}
			}
		}
	}

	.Dialogue-panel {
		position: absolute;
		left: 9.5em;
		right: 9.5em;
		padding: 0 5em;
		// overflow: hidden;
		.Dialogue-scrollLeftBtn, 
		.Dialogue-scrollRightBtn {
			position: absolute;
			height: 4.5em;
			width: 2.25em;
			bottom: 2em;
			@include opacity(0.25);
			&.active {
				@include opacity(1);
				cursor: pointer;
			}
		}
		.Dialogue-scrollLeftBtn {
			left: 0;
			background-image: url('../../../assets/images/icon-arrow-left.svg');
		}
		.Dialogue-scrollRightBtn {
			right: 0;
			background-image: url('../../../assets/images/icon-arrow-right.svg');
		}	
		.Dialogue-options {
			padding-top: 1em;
		
			.Dialogue-optionsText {
				color: #292929;
				text-align: center;
				margin: 1.5em auto 0 auto;
				span {
					font-size: 1.67em;
				}

			}
			.Dialogue-optionsCarouselWrap {
				border-left: 0.25em solid #EF571D;
				border-right: 0.25em solid #EF571D;
				padding: 1em 0.6em;
				.Dialogue-optionsCarousel {
					position: relative;
					width: 100%;
					height: 100%;
					overflow: auto;
					white-space: nowrap;
					scroll-behavior: smooth;
					@include hide-scrollbar();					
					.Dialogue-option {
						display: inline-block;
						vertical-align: middle;
						width: 23.25em;
						margin-right: 1em;
						min-height: 7.4em;
						max-height: 11.1em;
						border-radius: 2.25em;
						background-color: #EF571D;
						will-change: transform;
						padding: 1.5em;
						touch-action: none;
						white-space: normal;
						cursor: pointer;
						@include no-select();
						&:nth-last-child(1) {margin-right: 0;}
						span {
							color: white;
							font-size: 1.5em;
						}
					}
				}	
			}
			
		}
	}

	.Dialogue-playAgainBtn {
		position: absolute;
		bottom: 1em;
		left: calc(50% - 5.625em);
	}
	
	.Dialogue-infoBtn {
		position: absolute;
		top: 1em;
		right: 5em;
	}
}