@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ResourcesPopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#004E3E, 0.6);
	@include flex('center', 'center');

	.ResourcesPopup-content  {
			position: relative;
			width: 23.56em;		
			.ResourcesPopup-header {
				position: relative;
				z-index: 2;
				width: 100%;
				height: 3.6em;
				padding-left: 1.5em;
				background-image: url('../../assets/images/backgrounds/background-login-header.svg');
				@include flex('flex-start', 'center');
				
				.ResourcesPopup-title {
					font-size: 1.5em;
					font-weight: 600;
					text-transform: uppercase;
					color: white;
				}
			}	
	
			.ResourcesPopup-body {
				position: relative;
				z-index: 1;
				width: 23.3em;
				margin: -0.2em auto 0 auto;
				background-color: white;
				padding: 1.5em 1.4em;
				.ResourcesPopup-resourceGroup {
					margin-bottom: 1.5em;
					.ResourcesPopup-text {
						font-size: 1.11em;
						color: #292929;
					}
					.ResourcesPopup-buttons {
						margin-top: 1em;
						width: 100%;
						.ResourcesPopup-button {
							display: inline-block;
							width: 100%;
							height: 3em;
							color: white;
							background-image: url('../../assets/images/buttons/button-red-download.svg');
							padding-top: 0.25em;
							margin-top: 1.11em;
							text-align: center;
							text-decoration: none;
							cursor: pointer;
							@include no-select();
							@include flex('center', 'center');
						
							span {
								display: inline-block;
								text-transform: uppercase;
								font-size: 1.11em;
								font-weight: 600;
								// letter-spacing: 0.2em;	
							}
							&:nth-child(1) {
								margin-top: 0;
							}
						}
					}
				}
				
			}
	}
}