@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Logos {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5.56em;
	background-color: rgba(white, 0.5);
	
	.Logos-kfs {
		position: absolute;
		left: 5em;
		top: 0;
		width: 	25.11em;
		height: 100%;
		background-image: url('../../../assets/images/logo-kfs.png');
	}
	.Logos-support {
		position: absolute;
		left: 0em;
		top: 0;
		width: 100%;
		height: 7em;
		background-image: url('../../../assets/images/logo-support.png');
	}
	.Logos-cgl {
		position: absolute;
		top: 0;
		right: 5em;
		display: inline-block;
		width: 10.7em;
		height: 100%;
		background-image: url('../../../assets/images/logo-cgl.png');
		text-decoration: none;
		color: transparent;
	}

}