@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.BrainstormGroup {
	position: relative;
	padding-top: 5.5em;
	@include flex('center', 'flex-start');
	&.part-2 {
		padding-top: 3.5em;
	}
	.BrainstormGroup-part1 {
		width: 46.5em;		
		.BrainstormGroup-textarea {
			width: 100%;
			height: 10.33em;
			resize: none;
			color: #292929;
			background-color: white;
			border: 1px solid #EF571D;
			padding: 0.67em;
			font-size: 1.5em;
		}
		.BrainstormGroup-buttons {
			width: 25em;
			margin: 2em auto 0 auto;
			@include flex('space-between', 'flex-start');
		}
	}

	.BrainstormGroup-part2 {
		width: 70.5em;		
		.BrainstormGroup-category {
			padding: 1em;
			margin-bottom: 1em;
			height: 26em;
			.BrainstormGroup-categoryTitle {
				text-align: center;
				span {
					font-size: 1.5em;
					line-height: 1;
					color: #292929;
					text-transform: uppercase;
					font-weight: 600;
				}
				.BrainstormGroup-options {
					margin-top: 2em;
					.BrainstormGroup-option {
						position: relative;
						@include flex('flex-start', 'center');
						margin-bottom: 1em;
						span {
							text-align: left;
							text-transform: none;
							font-size: 1.5em;
							color: #292929;
							font-weight: 400;
						}
						&::after {
							position: absolute;
							content: '';
							top: 0.35em;
							left: -1.25em;
							width: 0.5em;
							height: 0.5em;
							border-radius: 50%;
							background-color: #292929;
						}
					}
				}
			}
			
			.BrainstormGroup-categorySubmissions {
				margin-top: 1em;
				height: 10em;
				.BrainstormGroup-categorySubmission {
					position: relative;
					display: inline-block;
					max-width: 15em;
					margin: 1em;
					padding: 1em;
					color: white;
					background-color: #EF571D;
				
					.BrainstormGroup-removeSubmissionBtn {
						position: absolute;
						right: -0.75em;
						top: -0.75em;
						width: 1.67em;
						height: 1.67em;
						background-image: url('../../../assets/images/icon-remove.svg');
						cursor: pointer;
					}
				}
			}	
		}
		.BrainstormGroup-textarea {
			width: 50%;
			height: 10.33em;
			resize: none;
			color: #292929;
			background-color: white;
			border: 1px solid #EF571D;
			padding: 0.67em;
			font-size: 1.5em;
			margin-left: 25%;
		}
		.BrainstormGroup-button {
			margin-top: 1em;
			@include flex('center');
			.red { 
				width: 20em;
				background-size: 20em auto;
			}
		}
	}


	.BrainstormGroup-infoBtn {
		position: absolute;
		top: 1em;
		right: 5em;
	}
}