@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.DialogueExamples {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 2.3em 2.7em 0 2.7em;
	.DialogueExamples-title {
		margin-bottom: 1em;
		text-align: center;
		span {
			font-size: 1.7em;
			font-weight: 600;
			text-transform: uppercase;
			color: #292929;
		}
	}
	.DialogueExamples-examples {
		@include flex('center', 'flex-start');
		&.amount-2 {@include flex('space-between', 'flex-start');}

		.DialogueExamples-example {
			width: 46.83em;
			.DialogueExamples-exampleTitle {
				text-align: center;
				margin-bottom: 0.75em;
				span {
					font-size: 1.5em;
					font-weight: 600;
					text-transform: uppercase;
					color: #292929;
				}
			}
			.DialogueExamples-exampleDialogue {
				height: 31.3em;
				background-image: url('../../../assets/images/frames/frame-dialogue-example-1.svg');
				padding: 2em 4.1em 0 2.4em;
				&.big {
					height: 38.8em;
					padding-top: 2.8em;
					background-image: url('../../../assets/images/frames/frame-dialogue-example-2.svg');
				}
				.DialogueExamples-exampleDialogueRow {
					margin-bottom: 0.5em;
					@include flex('space-between', 'center');
					.DialogueExamples-exampleDialoguePerson {
							width: 6.1em;
							height: 6.1em;
							border-radius: 100%;
							@include flex('center', 'center');
							span {
								color: white;
							}
					}
					.DialogueExamples-exampleDialogueText {
						width: 32.75em;
						border: 0.11em solid transparent;
						padding: 1.2em 1.8em;
						span {
							font-size: 1.44em;
							font-weight: 600;
							color: #292929;

						}
					}

					&:nth-child(odd) {
						.DialogueExamples-exampleDialoguePerson {background-color: #EF571D;}
						.DialogueExamples-exampleDialogueText {border-color: #EF571D}
					}
					&:nth-child(even) {
						.DialogueExamples-exampleDialoguePerson {background-color: #954D9E;}
						.DialogueExamples-exampleDialogueText {border-color: #954D9E}
					}
				}
			}
		}
	}
}