@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.OrganizeDndDragLayer {
	.OrganizeDndDragLayer-item { 
		position: relative;
		width: 21em;
		height: 7.8em;
		border-radius: 2.25em;
		background-color: #EF571D;
		transform-origin: center center;
		@include rotate(-7deg);
		.OrganizeDndDragLayer-text {
			position: relative;
			width: 100%;
			height: 100%;
			padding: 1.25em 1.75em;
			@include flex('center', 'center');
			span {
				font-size: 1.44em;
				color: white;
			}
		}
	}
}