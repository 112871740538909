@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GroupLobby {
	position: relative;
	height: 100%;
	padding: 8.5em 2em 2em 2em;

	.GroupLobby-logo {
		position: absolute;
		top: 0em;
		left: 0em;
	}

	.GroupLobby-wrap {
		position: relative;
		width: 102.55em;
		height: 100%;
		background-color: #F2F3F3;
		background-image: url('../../assets/images/backgrounds/background-game.svg');
		background-size: 44.5em auto;
		background-position: center center;
		padding: 1.5em;
		margin: auto;
		@include flex('center', 'center', 'column');

		.GroupLobby-text {
			font-size: 2.33em;
			color: #EF571D;
			font-weight: 700;
			text-transform: uppercase;
		}

		.GroupLobby-logoutBtn {
			position: absolute;
			left: 1.5em;
			bottom: 1.5em;
		}
	}
}
