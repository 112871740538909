@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Audio {
	position: relative;
	width: 30.9em;
	height: 16.2em;
	height: 13em;
	border-radius: 1.1em;
	border: 0.11em solid #EF571D;
	background-color: white;
	padding: 1.6em 1.25em;
	.Audio-title {
		margin-bottom: 1.7em;
		span {
			font-size: 1.5em;
			font-weight: 600;
			text-transform: uppercase;
			color: #292929;
		}
	}

	.Audio-player {
		width: 100%;
		height: 4.9em;
		border-radius: 2.44em;
		background-color: rgba(#292929, 0.6);
		.rhap_container {
			width: 100%;
			height: 100%;
			padding: 0;
			background-color: transparent;
			box-shadow: none;
			.rhap_main {
				@include flex('space-between', 'center', 'row-reverse');
				.rhap_progress-section {
					display: block;
					width: calc(100% - 5.5em);
					height: 100%;
					padding: 1.7em 1.6em 0 1em;
					.rhap_progress-container {
						display: inline-block;
						width: 100%;
						height: 1.11em;
						padding-top: 0.5em;
						margin: 0;
						.rhap_progress-bar {
							background-color: #FFFFFF;
							height: 0.2em;
							border-radius: 0;
							.rhap_progress-indicator {
								width: 1.1em;
								height: 1.1em;
								margin-left: -0.55em;
								top: -0.44em;
								background-image: url('../../../assets/images/icon-audio-progress.svg');
								background-size: contain;
								background-color: transparent;
							}
							.rhap_progress-filled {
								background-color: #FCE5DC;
								border-radius: 0;
							}
						}
					}
					.rhap_time {
						display: inline-block;
						vertical-align: top;
						width: 50%;
						color: #FFFFFF;
						font-size: 1em;
						&.rhap_current-time {
							text-align: left;
						}
						&.rhap_total-time {
							text-align: right;
						}

					}
				}
				.rhap_controls-section {
					width: 5.5em;
					height: 100%;
					margin: 0;
					.rhap_main-controls {
						button {
							font-size: 1em;
							width: 4.44em;
							height: 4.44em;
							svg {
								width: 100%;
								height: auto;
							}
						}
					}
				}
			}
		}
	}

}