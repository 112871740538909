@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Instructions {
	position: relative;
	width: 51em;
	margin-bottom: 1em;
	border-bottom: 0.15em solid #EF571D;
	.Instructions-header {
		position: relative;
		width: 100%;
		height: 3.9em;
		background-image: url('../../../assets/images/backgrounds/background-gamepage-box.svg');
		background-position: top center;
		padding: 0 1em;
		@include flex('space-between', 'center');
		z-index: 2;
		.Instructions-title {
			span {
				font-size: 1.5em;
				color: white;
				text-transform: uppercase;
			}
		}
		.Instructions-toggleBtn {
			width: 2.5em;
			height: 2.5em;
			background-image: url('../../../assets/images/buttons/button-instructions.svg');
			background-size: 2.2em auto;
			cursor: pointer;
		}
	}
	.Instructions-body {
		position: relative;
		border-right: 0.15em solid #EF571D;
		border-left: 0.15em solid #EF571D;
		background-color: white;
		padding: 1.5em 1em;
		margin-top: -0.4em;
		z-index: 1;
		.Instructions-subtitle {
			color: #EF571D;
			margin-bottom: 0.25em;
			span {
				font-size: 1.5em;
			}
		}
		.Instructions-text {
			p {
				color: #292929;
				font-size: 1.5em;
				line-height: 1.25;
				&:nth-child(1) {margin-top: 0;}
				&:nth-last-child(1) {margin-bottom: 0;}
			}
			li {
				font-size: 1.5em;
				line-height: 1.25;
			}
		}
		.Instructions-buttons {
			margin-top: 1em;
		}

	}

	&.closed {
		border: none;
		.Instructions-toggleBtn {
			@include flip(1, -1);
		}
		.Instructions-body { 
			height: 0;
			padding: 0;
			overflow: hidden;
			border: none;
		}
	}
}